import * as React from 'react';
import {useEffect} from 'react';
import Typography from '@mui/material/Typography';
import {createTheme} from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {AppProvider} from '@toolpad/core/AppProvider';
import {DashboardLayout} from '@toolpad/core/DashboardLayout';
import type {Navigation, Router, Session} from '@toolpad/core';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import WelcomePage from '../pages/WelcomePage'
import ImportRegistryPage from '../pages/ImportRegistryPage'
import {getCookie, setCookie} from "../hooks/jwtCookie";
import BankReportPage from "../pages/BankReportPage";
import MerchantRegistryPage from '../pages/MerchantRegistryPage';
import SettingsPage from "../pages/SettingsPage";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {hasRole, Role} from "../hooks/auth";

const NAVIGATION: Navigation = [
    {
        segment: 'dashboard',
        title: 'Dashboard',
        icon: <DashboardIcon/>,
    },
    {
        segment: 'import',
        title: 'Входящие реестры',
        icon: <RequestQuoteIcon/>,
        pattern: "/import"
    },
    {
        segment: 'bankreport',
        title: 'Исходящие реестры',
        icon: <RequestQuoteIcon/>,
        pattern: "/import"
    },
    {
        segment: 'merchantregistry',
        title: 'Реестр мерчантов',
        icon: <DashboardIcon/>,
        pattern: "/merchantregistry"
    },
    {
        segment: 'settings',
        title: 'Настройки',
        icon: <AccountBoxIcon/>,
        pattern: "/settings"
    }
];

const theme = createTheme({
    palette: {
        mode: 'light', // можно сменить на 'dark' для темной темы
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 600,
            lg: 1200,
            xl: 1536,
        },
    },
});

function CommonPageContent({pathname}: { pathname: string }) {
    switch (pathname) {
        case '/dashboard':
            return <WelcomePage/>;
        case '/import':
            return <ImportRegistryPage canUpload={hasRole(Role.Finance)}/>;
        case '/bankreport':
            return <BankReportPage/>;
        case '/merchantregistry':
            return <MerchantRegistryPage/>;
        case '/settings':
            return <SettingsPage/>;
        default:
            return <Typography>Page not found</Typography>;
    }
}

interface CommonProps {
    /**
     * Injected by the documentation to work in an iframe.
     * Remove this when copying and pasting into your project.
     */
    window?: () => Window,
    onLogout?: () => void
}

export default function DashboardLayoutAccount(props: CommonProps, {children}: {
    children: React.ReactNode
}) {
    const {window, onLogout} = props;

    const [session, setSession] = React.useState<Session | null>({
        user: {
            name: '--',
            email: '--',
            image: '#',
        },
    });

    useEffect(() => {
        const loadUserInfo = async () => {
            const jwtToken = getCookie('jwtToken');
            const response = await fetch('/api/user/', {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();

                setSession({
                    user: {
                        name: data.name,
                        email: data.phone,
                        image: '#',
                    },
                });
            } else {
                console.error('Failed to fetch records');
            }
        };
        loadUserInfo();
    }, [])

    const authentication = React.useMemo(() => {
        return {
            signIn: () => {
                setSession({
                    user: {
                        name: '-',
                        email: '-',
                        image: '#',
                    },
                });
            },
            signOut: () => {
                setCookie('jwtToken', '', 0);
                setSession(null);
                if( typeof onLogout === 'function' ) {
                    onLogout();
                }
            },
        };
    }, []);

    const [pathname, setPathname] = React.useState('/dashboard');

    const router = React.useMemo<Router>(() => {
        return {
            pathname,
            searchParams: new URLSearchParams(),
            navigate: (path) => setPathname(String(path)),
        };
    }, [pathname]);

    // Remove this const when copying and pasting into your project.
    const commonWindow = window !== undefined ? window() : undefined;

    return (
        // preview-start
        <AppProvider
            session={session}
            authentication={authentication}
            navigation={NAVIGATION}
            branding={{
                logo: '',
                title: 'SMPT PARTNER',
            }}
            router={router}
            theme={theme}
            window={commonWindow}
        >
            <DashboardLayout>
                <CommonPageContent pathname={pathname}/>
            </DashboardLayout>
        </AppProvider>
        // preview-end
    );
}