import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    CardContent,
    Chip,
    FormControl,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
    Button
} from "@mui/material";
import {getCookie} from "../hooks/jwtCookie";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {QRCodeSVG} from 'qrcode.react';

const SettingsPage = () => {
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [isGaEnabled, setIsGaEnabled] = useState(false);
    const [gaQR, setGaQR] = useState('');
    const [gaSecret, setGaSecret] = useState('');
    const [totpCode, setTotpCode] = useState(''); // Состояние для TOTP кода

    const requestGa = async () => {
        const jwtToken = getCookie('jwtToken');
        const response = await fetch('/api/user/ga/qr', {
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();

            setGaQR(data.qr);
            setGaSecret(data.secret);
        } else {
            console.error('Failed to fetch records');
        }
    }

    const verifyTotpCode = async () => {
        const jwtToken = getCookie('jwtToken');
        const response = await fetch('/api/user/ga/enable', {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                secret: gaSecret,
                code: totpCode
            })
        });

        if (response.ok) {
            const data = await response.json();
            setIsGaEnabled(true);
        } else {
            console.error('TOTP verification failed');
        }
    }

    useEffect(() => {
        const loadUserInfo = async () => {
            const jwtToken = getCookie('jwtToken');
            const response = await fetch('/api/user/', {
                headers: {
                    'Authorization': `Bearer ${jwtToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const data = await response.json();

                setName(data.name);
                setPhone(data.phone);
                setUsername(data.username);
                setIsGaEnabled(data.has_ga);

                if (!data.has_ga) {
                    requestGa();
                }

            } else {
                console.error('Failed to fetch records');
            }
        };
        loadUserInfo();
    }, []);

    return (
        <Box
            sx={{
                p: 2,
            }}
        >

            <Typography variant="h4" gutterBottom component="div">
                Настройки
            </Typography>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    Профиль
                </AccordionSummary>
                <AccordionDetails>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-amount">Логин</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={username}
                            label="Amount"
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-amount">Имя</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={name}
                            label="Amount"
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{m: 1}}>
                        <InputLabel htmlFor="outlined-adornment-amount">Телефон</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            startAdornment={phone}
                            label="Amount"
                        />
                    </FormControl>
                </AccordionDetails>
            </Accordion>
            <Accordion>

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    Google Authenticator&nbsp;
                    {(isGaEnabled) ? (
                        <Chip label="включено" color="success" size={"small"} variant="outlined"/>
                    ) : ""}
                </AccordionSummary>
                <AccordionDetails>
                    {/** QR CODE GOOGLE AUTH AND INPUT FORM FOR TOTP FOR ENABLE GA **/}
                    {!isGaEnabled ? (gaQR && (
                        <div>
                            <Typography variant={"body1"} gutterBottom>
                                Сканируйте QR-код с помощью Google Authenticator:
                            </Typography><br/>
                            <QRCodeSVG width={195} height={195} value={gaQR}/><br/>
                            <FormControl sx={{mt: 1}}>
                                <TextField
                                    label="Введите код из Google Authenticator"
                                    variant="outlined"
                                    value={totpCode}
                                    onChange={(e) => setTotpCode(e.target.value)}
                                />
                                <Button
                                    fullWidth={true}
                                    variant="contained"
                                    color="primary"
                                    onClick={verifyTotpCode}
                                    sx={{mt: 1}}
                                >
                                    Подтвердить
                                </Button>
                            </FormControl><br/>
                        </div>
                    )):(
                        <Typography variant={"body1"} gutterBottom>
                            Ваш аккаунт защищен Google Authenticator.<br/> <br/>
                            <i>Если вы хотите отключить его или
                            изменить настройки в будущем, свяжитесь с нашей службой поддержки.</i>
                        </Typography>
                    )}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default SettingsPage;