import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

interface Merchant {
    bin: string;
    merchant_name: string;
    balance_bb: number;
    balance_qb: number;
    turnover_from_bb: number;
    turnover_from_qb: number;
}

const formatAmount = (amount: string | number | null): string => {
    if (!amount) return '0.00';

    if (typeof amount === 'number') {
        amount = amount.toString();
    }

    amount = amount.replace(',', '.');
    const parts = amount.split('.');
    const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Форматирование разделителей тысяч
    const decimalPart = parts[1] ? parts[1].padEnd(2, '0') : '00'; // Всегда два знака после точки
    return `${integerPart}.${decimalPart}`;
};

function MerchantRow({ merchant }: { merchant: any }) {
    return (
        <TableRow>
            <TableCell>{merchant.bin}</TableCell>
            <TableCell>{merchant.merchant_name}</TableCell>
            <TableCell align="right">{formatAmount(merchant.balance_bb)}</TableCell>
            <TableCell align="right">{formatAmount(merchant.balance_qb)}</TableCell>
            <TableCell align="right">{formatAmount(merchant.turnover_from_bb)}</TableCell>
            <TableCell align="right">{formatAmount(merchant.turnover_from_qb)}</TableCell>
        </TableRow>
    );
}

const MerchantRegistryPage: React.FC = () => {
    const [merchants, setMerchants] = useState([]);

    useEffect(() => {
        const fetchMerchants = async () => {
            const jwtToken = document.cookie.split('; ').find(row => row.startsWith('jwtToken='))?.split('=')[1];
            try {
                const response = await fetch('/api/merchant-registry/list', {
                    headers: {
                        'Authorization': `Bearer ${jwtToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setMerchants(data);
                } else {
                    console.error('Failed to fetch merchant registry:', response.status);
                }
            } catch (error) {
                console.error('Error fetching merchant registry:', error);
            }
        };

        fetchMerchants();
    }, []);

    return (
        <Box sx={{ p: 2 }}>
            <Typography variant="h4" gutterBottom component="div">
                Реестр мерчантов
            </Typography>
            <TableContainer component={Paper}>
                <Table aria-label="merchant registry table">
                    <TableHead>
                        <TableRow>
                            <TableCell>BIN</TableCell>
                            <TableCell>Название мерчанта</TableCell>
                            <TableCell align="right">Баланс BB</TableCell>
                            <TableCell align="right">Баланс QB</TableCell>
                            <TableCell align="right">Оборот с BB</TableCell>
                            <TableCell align="right">Оборот с QB</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {merchants.map((merchant: Merchant) => (
                            <MerchantRow key={merchant.bin} merchant={merchant} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default MerchantRegistryPage;
